a  {
    text-decoration: none !important;
}

.b-r-10 {
    border-radius: 10px !important;
}

.b-r-15 {
    border-radius: 15px !important;
}

.b-r-20 {
    border-radius: 20px;
}

.b-r-25 {
    border-radius: 25px;
}

.b-r-30 {
    border-radius: 30px;
}

.b-r-40 {
    border-radius: 40px;
}

.b-r-50 {
    border-radius: 50%;
}

.fs-7 {
    font-size: 0.8em;
}

.touch-ink {
    color: #aaaaaa;
    border-radius: inherit;
}

.overflow-x-hidden {
    overflow-x: scroll;
}

.overflow-x-hidden::-webkit-scrollbar {
    display: none;
}

.overflow-y-hidden {
    overflow-y: auto;
}

.overflow-y-hidden::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.fw-semibold {
    font-weight: 500 !important;
}

.fw-100{
    font-weight: 100 !important;
}
.fw-150{
    font-weight: 150 !important;
}
.fw-200{
    font-weight: 200 !important;
}
.fw-250{
    font-weight: 250 !important;
}
.fw-300{
    font-weight: 300 !important;
}
.fw-350{
    font-weight: 350 !important;
}
.fw-400{
    font-weight: 400 !important;
}
.fw-450{
    font-weight: 450 !important;
}
.fw-500{
    font-weight: 500 !important;
}
.fw-550{
    font-weight: 550 !important;
}
.fw-600{
    font-weight: 600 !important;
}
.fw-650{
    font-weight: 650 !important;
}
.fw-700{
    font-weight: 700 !important;
}
.fw-750{
    font-weight: 750 !important;
}
.fw-800{
    font-weight: 800 !important;
}
.fw-850{
    font-weight: 850 !important;
}
.fw-900{
    font-weight: 900 !important;
}
.loader {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
