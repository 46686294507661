html * {
    font-family: "Nunito", sans-serif !important;
    scroll-behavior: smooth !important;
}

.store-not-active {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.store-not-active-click {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    pointer-events: none;
}

.error-shake {
    -webkit-animation: shake 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation: shake 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

/* inventory */

.ant-tabs-ink-bar {
    border-bottom: 2px solid #fea24b;
}

.inventory-section {
    background-color: #fff;
}

.navLinks {
    font-size: 12px;
    font-weight: 500;
    color: #09051c;
    opacity: 0.5;
}

.activeNav {
    font-size: 16px;
    font-weight: 600;
    color: #fea24b;
    border-bottom: 2px solid #fea24b;
}

.searchBar {
    border-radius: 12px;
    height: 50px;
    box-shadow: inset 0px 4px 45px rgba(0, 0, 0, 0.08);
}

.inventory-search::placeholder {
    color: #aca8a5;
    font-size: 14px;
}

.inventory-search:focus {
    box-shadow: none;
    background-color: none;
    outline: none;
}

.inventory-search {
    border: none;
    background: none;
}

.inventory-product-heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 131.02%;
    color: #898791;
}

.inventory-item-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 131.02%;
    color: #898791;
}

.inventory-item-price {
    font-weight: 600;
    font-size: 14px;
    line-height: 131.02%;
    color: #898791;
}

.inventory-item-desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 131.02%;
    color: #898791;
    opacity: 0.5;
}

.inventory-btn {
    position: fixed;
    bottom: 79px;
    right: 5px;
}

.inventory-browse-btn {
    border: none;
    border-radius: 12px;
    padding: 10px 5px;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
}

.inventory-add-btn {
    border: 1px solid #fea24b;
    border-radius: 12px;
    padding: 10px 5px;
    color: #fea24b;
}

.inventory-add-on-count-head {
    background: rgba(255, 146, 20, 0.1);
    border: none;
    line-height: 131.02%;
    color: #898791;
    border-radius: 10px;
}

.inventory-add-on-item-head {
    background: rgba(255, 144, 18, 0.1);
    border: none;
    border-radius: 10px;
}

.inventory-item-addon-count {
    font-weight: 500;
    font-size: 14px;
    line-height: 131.02%;
    color: #4d9cf9;
}

.inventory-title {
    font-weight: 700;
    font-size: 23px;
    line-height: 131.02%;
    color: #09051c;
}

/* tabpane start */

.ant-tabs-nav {
    width: 100% !important;
}

.ant-tabs-tab {
    display: block;
    flex: 1;
    text-align: center !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fea24b !important;
    font-weight: 500;
}

.ant-tabs-tab-btn {
    color: #c9bebe !important;
    font-weight: 500;
}

/* tabpane end */

/* inventory */

/* login-page starts */

.login-style {
    background-image: url("../images/login.bg.png");
    background-size: contain;
    background-repeat: round;
    height: 70vw;
    opacity: 0.2;
    z-index: -99;
}

.login-logo {
    position: absolute;
    top: 10%;
    width: 80%;
    right: 10%;
    text-align: center;
}

/* .input-login-phone {
    color: #000000;
    border: none;
    border-radius: 0px;
}

.input-login-phone:focus {
    box-shadow: none;
    background-color: none;
}

.input-login-phone:active {
    box-shadow: none;
    background-color: none;
} */

.input-91 {
    background-color: #fff !important;
    border: none !important;
}

.phone-input {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: none;
    outline: none;
    text-align: center;
    white-space: nowrap;
    background: #ffffff;
    border: 1px solid #f4f4f4;
    box-shadow: 12px 15px 50px rgba(90, 108, 234, 0.07);
    border-radius: 15px;
}

.login-btn {
    width: 100%;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    height: 60px;
    border: none;
    color: #ffffff;
}

.register-btn {
    width: 50%;
    height: 40px;
    background: #f3f3f3;
    border-radius: 15px;
    border: none;
    margin: 0 auto;
    color: #fd8814;
}

/* login-page ends */

/* footer */

.footer-icon-text {
    color: #ff9a62;
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    /* opacity: 1 !important; */
}

.footer-icon {
    color: #ff9a62;
}

/* .footer-btn {
    border-radius: 12px;
    background: rgba(255, 154, 98, 0.1);
} */

.footer-container {
    background: #ffffff;
    box-shadow: 0px 0px 50px rgba(90, 108, 234, 0.1);
    border-radius: 22px 22px 0px 0px;
}

.footer-btn-btn {
    outline: none !important;
    border: none;
    background: rgba(255, 154, 98, 0.1);
    border-radius: 12px;
}

/* footer ends */

/* past order  */

.past-order-container {
    background: rgba(255, 144, 18, 0.1);
    /* opacity: 0.1; */
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
}

.past-order-tag {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 10px;
    width: fit-content;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
}

.past-order-id {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #898791;
}

.past-order-date {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #919092;
}

.past-order-price {
    font-weight: 630;
    font-size: 13px;
    line-height: 18px;
    color: #898791;
}

/* past order end */

/* explore restaurant */

.store-toggle-btn {
    background: #ff9012;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    color: #fff;
    border-radius: 12px;
    /* height: 25px; */
    /* width:70px */
}

.heading {
    background-color: #ffffff;
    margin-top: 1.4rem;
    padding-left: 1rem;
}

.nameRestaurant {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #38393d;
}

.address {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #696b6d;
}

.search {
    padding-right: 1rem;
    margin-top: 1.8rem;
}

.status {
    background-color: #f9f7f7;
}

.status-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #32ba7c;
    /* padding-left: 1rem; */
}

.status-offline {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #ee2525;
    /* padding-left: 1rem; */
}

.card-restaurant {
    background: #fff; /* White background */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); /* More prominent shadow */
    border-radius: 12px;
    margin-right: 1rem;
    margin-left: 1rem;
    /* margin-top: 1rem; */
}


.addon-combo {
    background: #fff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.02);
    border-radius: 12px;
    /* margin-top: 1rem; */
}

.explore-heading {
    font-size: 13px;
}

.explore-id {
    font-size: 13px;
    font-weight: bold;
}

.explore-general-one {
    font-size: 13px;
    font-weight: bold;
}

.explore-general-two {
    font-size: 13px;
}

.explore-general-total {
    font-size: 16px;
    font-weight: bold;
}

.explore-general-amount {
    font-size: 16px;
}

.decline-button {
    border: 1px solid #fea24b;
    background-color: #ffffff;
    border-radius: 15px;
    width: 45%;
    color: #fea24b;
    font-weight: 500;
}

.accept-button {
    background-color: #fea24b;
    border-radius: 15px;
    border: none;
    width: 45%;
    white-space: nowrap;
    color: #ffffff;
    font-weight: 500;
}

.accept-button-ready-pickup {
    background-color: #fea24b;
    border-radius: 15px;
    border: none;
    white-space: nowrap;
    color: #ffffff;
    font-weight: 500;
}

.prepare-btn {
    border: 1px solid #009c51;
    background-color: #ffffff;
    border-radius: 15px;
    width: 45%;
    /* color:; */
    font-weight: 500;
}

.vendor-store-btn {
    bottom: 105px;
}

.medicine-button {
    background-color: #f8f8f8;
    border-radius: 12px;
    width: 40%;
    margin-right: 1rem;
    border: none;
    margin-top: .5rem;
    color: #fea24b;
    font-weight: 450;
    font-size: 14px;
}

.card {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    mix-blend-mode: normal;
    border-radius: 16px;
}

.no-order-text {
    font-weight: 550;
    font-size: 28px;
    margin: 0 auto;
    margin-top: 30vh;
    text-shadow: 2px 2px 8px #c1baba;
    /* color: #f49237; */
}

.order-status-btn-active {
    width: 100%;
    background: #fd8814;
    height: 40px;
    font-weight: 700;
    font-size: 14px;
    opacity: 0.7;
    border: 1px solid #fea24b;
    border-radius: 10px;
    display: block;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    color: #fff;
}

.order-status-btn {
    width: 100%;
    background: #ffffff;
    height: 40px;
    font-weight: 700;
    font-size: 14px;
    opacity: 0.7;
    border-radius: 10px;
    border: 1px solid #fea24b;
    display: block;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    color: #fd8814;

}

/* end  */

.delivery-button {
    background-color: #fea24b;
    border-radius: 20px;
    width: 90%;
    margin-left: 1rem;
    margin-right: 1rem;
    /* margin-top: 1rem; */
    border: none;
}

.delivery-text {
    color: #ffffff;
    font-weight: 500;
}

/* end  */

/* Prescription Page */

.prescription-heading {
    margin-top: 1rem;
}

.prescription-title {
    font-size: large;
    font-weight: bolder;
}

.prescription-image {
    position: relative;
    top: 15%;
    width: 80%;
    left: 10%;
    text-align: center;
}

.prescription-buttons {
    position: absolute;
    margin-top: 1.5rem;
    width: 100%;
    bottom: 0;
}

.decline-button-pres {
    border: 1px solid #fea24b;
    background-color: #ffffff;
    border-radius: 20px;
    width: 40%;
    margin-left: 1rem;
}

.accept-button-pres {
    background-color: #fea24b;
    border-radius: 20px;
    width: 40%;
    margin-right: 1rem;
    border: none;
}

.accept-text-pres {
    color: #ffffff;
    font-weight: 500;
}

.decline-text-pres {
    color: #fea24b;
    font-weight: 500;
}

/* end */

/* backbutton */

.back-button {
    /* position: sticky; */
    background-color: #fff1e4;
    border-radius: 12px;
}

.back-button-title {
    font-weight: bold;
    /* margin-left: 4.5rem; */
    /* margin-left: 4.5rem; */
    /* margin-top: 2rem; */
}

/* end */

/* vendor profile */

.profile-heading {
    background-color: #ffffff;
}

.vendor-profile-toggle {
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
}

.profile-tabs {
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
}

.profile-name {
    font-weight: 800;
    font-size: 22px;
    line-height: 21.82px;
}

.profile-address {
    font-size: 16px;
}

.vendor-profile-menu-item {
    background: #ffffff;
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
    border-radius: 12px;
    padding: 12px 18px;
    margin-top: 12px;
    text-decoration: none;
    color: #09051c;
}

.vendor-profile-logout {
    background: rgba(255, 144, 18, 0.1);
    border-radius: 10px;
    width: 147px;
    height: 32px;
    color: #ff9012;
    margin: 0 auto 50px auto;
    font-size: 14px;
}

/* end */

/* vendor offer starts */

.offer-input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #f4f4f4;
    box-shadow: 12px 15px 50px rgba(90, 108, 234, 0.09);
    border-radius: 12px;
}

.offer-box {
    border-radius: 10px;
    background-color: rgba(217, 217, 217, 0.2);
}

.discount-btn {
    /* width: 100%;
    height: 50px; */
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    border: none;
}

.offer-discnt-text {
    font-weight: 600;
    font-size: 15px;
    line-height: 131.02%;
    color: #898791;
}

.offer-coupuon-order-btn {
    bottom: 18%;
    right: 25%;
}

.offer-coupon-btn1 {
    background: #ffffff;
    box-shadow: 6px 4px 20px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    border: none;
    padding: 8px 10px;
    font-weight: 700;
    font-size: 10px;
}

.offer-coupon-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 131.02%;
    color: #09051c;
}

.vendor-coupon-code {
    font-weight: 850;
    font-size: 1.4em;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    writing-mode: vertical-lr;
    height: 35vw;
    width: 10vw;
}

.coupon-borders {
    border-radius: 0px 15px 15px 0px;
    border-left: 3px dashed #c7c7c7;
    border-top: 1px solid #fd8814;
    border-bottom: 1px solid #fd8814;
    border-right: 1px solid #fd8814;
}


.coupon-btn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    box-shadow: 5px 5px 24px rgba(20, 78, 90, 0.2);
    border-radius: 15px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #fefeff;
    border: none;
}

.coupon-btn-close {
    background: linear-gradient(98.81deg, #e8e8e8 -0.82%, #dbdbdb 101.53%);
    box-shadow: 5px 5px 24px rgba(20, 78, 90, 0.2);
    border-radius: 15px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #5c5c5c;
    border: none;
}

.offer-sheet-btn {
    height: 45px;
    border: 1px solid #d9d9d9;
    border-radius: 13px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #fd9a28;
    background: #ffffff;
    width: 100%;
}

.offer-sheet-btn-active {
    height: 45px;
    border: 1px solid #d9d9d9;
    border-radius: 13px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    background: #fd9a28;
    color: #fff;
    width: 100%;
}

.offer-input-text {
    color: #000000;
    border: none;
    border-radius: 0px;
}

.offer-input-text:focus {
    box-shadow: none;
    background-color: none;
}

.offer-input-text:active {
    box-shadow: none;
    background-color: none;
}

.offer-input-text::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #3b3b3b;
    mix-blend-mode: normal;
    opacity: 0.3;
}

/* vendor offer ends  */

/* vendor outlet starts */

.outlet-header {
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    color: #38393d;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.outlet-place {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #696b6d;
}

.outlet-info-container {
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.outlet-link {
    background: #ffffff;
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
    border-radius: 12px;
    font-weight: 600;
    font-size: 15px;
    line-height: 131.02%;
    color: #09051c;
}

/* vendor outlet ends  */

/* Make Coupon  */

.coupon-heading-one {
    color: #fd8814;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
}

.coupon-heading-two {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #9e9ca9;
}

.input-coupon {
    border: none;
    background: #fff;
    border-radius: 0px;
}

.input-coupon::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: rgba(59, 59, 59, 0.4);
    mix-blend-mode: normal;
    /* opacity: 0.2; */
}

.input-coupons {
    border: none;
    border-radius: 0px;

}

.input-coupons::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    font-family: "Nunito";
    color: #3b3b3b;
    mix-blend-mode: normal;
    /* opacity: 0.3; */
}

.coupon-input {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    font-size: 12px;
    color: none;
    outline: none;
    /* text-align: center; */
    white-space: nowrap;
    background: #fff;
    border: 1px solid #f4f4f4;
    box-shadow: 0px 0px 10px 0px #f3f3f35e;
    border-radius: 15px;
}

.coupon-input-type {
    display: flex;
    color: none;
    outline: none;
    white-space: nowrap;
}

.input-coupon:focus {
    outline: none !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.input-coupon:active {
    outline: none !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.upload-button-coupon {
    background-color: rgba(254, 162, 75, 0.1);
    border-radius: 8px;
    border: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #ff9012;
}

.button-coupon {
    width: 100%;
    margin-bottom: 1.5rem;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border: none;
    border-radius: 10px;
    font-weight: 700;
    font-size: 15px;
    line-height: 131.02%;
    text-align: center;
    color: #ffffff;
}

.button-text-coupon {
    color: #ffffff;
    font-weight: bold;
}

.coupon-date {
    border: none !important;
}

.coupon-date-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #3b3b3b;
    mix-blend-mode: normal;
    opacity: 0.3;
}

.save-btn-timing {
    width: 100%;
    /* background-color: #ff9012; */
    border: none;
    border-radius: 10px;
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    box-shadow: 5px 5px 24px rgba(20, 78, 90, 0.2);
    /* border-radius: 15px; */
}

/*  coupon end  */

/* date wise sale start */

.date-wise-sale {
    font-weight: 700;
    font-size: 18px;
}

.total-order {
    display: flex;
    background: #ffffff;
    color: #898791;
    border-radius: 8px;
    height: 76px;
}

.orders {
    font-weight: 700;
    font-size: 16px;
}

.order-date {
    font-weight: 600;
    font-size: 12px;
}

.order-value {
    font-weight: 700;
    font-size: 14px;
}

.order-price {
    font-weight: 600;
    font-size: 12px;
}

.order-btn {
    background: rgba(50, 186, 124, 0.1);
    color: #32ba7c;
    font-weight: 500;
    font-size: 13px;
    border: none;
    border-radius: 10px;
    width: 25vw;
}

.export-report-btn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    border: none;
    border-radius: 10px;
    padding: 6px 10px;
}

.select-date-text {
    color: #f49237;
    font-weight: 600;
    font-size: 15px;
}

.no-sale-text {
    color: #f49237;
    font-weight: 600;
    font-size: 20px;
}

/* date wise sale end */

/* vendor account */

.vendor-account-head {
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #696b6d;
}

.vendor-account-container {
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.vendor-account-title {
    font-weight: 600;
    font-size: 15px;
    color: #09051c;
    line-height: 22px;
    margin: 0;
}

.vendor-account-details1 {
    font-weight: 500;
    font-size: 1.3em;
    line-height: 22px;
    color: #696b6d;
}

.vendor-account-details {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #696b6d;
}

/* end */

/* Open/close time */

.timing-day {
    font-size: large;
    font-weight: bolder;
}

.timing-text-one {
    padding-left: 1rem;
    padding-top: 10px;
}

.timing-text-two {
    padding-left: 1rem;
    padding-top: 8px;
}

.seperator-timing {
    border-width: 8px;
    color: rgba(217, 217, 217, 0.8);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.slot-add {
    color: #ff9012;
    font-weight: bold;
}

.copy-days {
    color: #ff9012;
    font-weight: bold;
}

.save-btn-timing {
    width: 100%;
    background-color: #ff9012;
    border: none;
    border-radius: 10px;
}

/* end  */

/* password change  */

.seperator-settings {
    border-width: 8px;
    color: rgba(217, 217, 217, 0.8);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    /* margin-bottom: 1rem; */
}

.settings-text-one {
    font-size: large;
    font-weight: 700;
}

.password-text {
    font-size: medium;
    font-weight: bold;
}

.password-change {
    background: #ffffff;
    box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.08);
    border-radius: 12px;
}

.input-password-change {
    background: #ffffff;
    border: 1px solid #f4f4f4;
    box-shadow: 12px 15px 50px rgba(90, 108, 234, 0.1);
    border-radius: 10px;
    margin-left: 1rem;
    margin-right: 1rem;
}

.password-submit {
    width: 100%;
    background-color: #ff9012;
    border: none;
    border-radius: 10px;
}

.acc-set-input:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

/* end  */

/* vendor-business-starts  */

.report-title {
    font-weight: 700;
    font-size: 21px;
}

.buisness-date-btn-active {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 10px;
    border: none;
    height: 40px;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    display: block;
}

.buisness-date-btn {
    background: #ffffff;
    color: #ff7b00;
    height: 40px;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    opacity: 0.7;
    border-radius: 10px;
    border: 2px solid #fea24b;
    display: block;
}

.graph-progress {
    background-color: rgba(217, 217, 217, 0.2);
    border-radius: 10px;
}

.progress-bar {
    border-bottom: 1px solid #e7e7e7;
}

.graph-header {
    font-weight: 700;
    font-size: 14px;
    color: #57565b;
}

.graph-past-order-btn {
    width: 100%;
    color: #fd8814;
    background-color: #fff;
    border: none;
    border-radius: 10px;
    height: 50px;
}

.payout-details {
    background: rgba(217, 217, 217, 0.4);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
}

.payout-details-header {
    font-weight: 700;
    font-size: 14px;
    color: #57565b;
}

.date-wise-sale-btn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    box-shadow: 5px 5px 24px rgba(20, 78, 90, 0.2);
    border-radius: 12px;
    width: 100%;
    border: none;
    color: #fff;
    height: 50px;
}

.ratings-scroll {
    display: flex;
    grid-template-columns: repeat(6, 1fr);
    overflow-x: scroll;
    column-gap: 15px;
    padding-right: 1rem;
}

.hidden-scroll-bar::-webkit-scrollbar {
    display: none;
}

.hidden-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.rating {
    background: rgba(217, 217, 217, 0.2);
    border-radius: 10px;
    width: 100%;
    padding-bottom: 4vw;
}

.rating-des {
    background: #fff;
    border-radius: 10px;
}

.star-rating {
    font-size: 1rem;
    color: #fd8814;
    margin-bottom: 1vw;
}

.rating-header {
    color: #09051c;
    font-weight: 600;
    font-size: 15px;
}

/* vendor-business-ends  */

/* order completed */

.order-completed-btn {
    position: fixed;
    bottom: 14%;
    right: 24%;
}

/* end */

/* preparation time */

.preparation-time-card {
    border: 2px solid #ff9012;
    border-radius: 15px;
}

.preparation-save-btn {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    box-shadow: 5px 5px 24px rgba(20, 78, 90, 0.2);
    border-radius: 15px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #fefeff;
    border: none;
}

/* end */

/* add menu */

.no-border-addmenu {
    border: none;
    border-color: white;
    box-shadow: 12px 15px 50px rgba(90, 108, 234, 0.07);
    border-radius: 10px;
}

.no-border-addmenu1 {
    border: none;
    border-color: white;
    box-shadow: 12px 15px 50px rgba(90, 108, 234, 0.07);
    border-radius: 10px;
    height: 115px;
}

.Addmenu-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    height: 57px;
    border-radius: 15px;
}

/* end */

/* end */

/* editcoupon */

.description {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #09051c;
}

.paragraph {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 131.02%;
    color: #898791;
}

.edit-coupon-btn {
    background: #ffffff;
    box-shadow: 6px 4px 20px rgba(0, 0, 0, 0.13);
    border-radius: 6px;
    border: none;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    color: #009c51;
    padding: 5px;
}

.vendor-coupon-btn {
    position: absolute;
    left: 74%;
    bottom: 4%;
    padding: 15px;
    padding-left: 3px;
}

/* add item start */

.add-on-btn {
    background: #4cd797;
    border: 1px solid #f4f4f4;
    box-shadow: 12px 15px 50px rgba(90, 108, 234, 0.07);
    border-radius: 18px;
    font-weight: 700;
    font-size: 16px;
    line-height: 131.02%;
    /* identical to box height, or 21px */
    text-align: center;
    color: #ffffff;
    width: 45%;
}

.item-add-on-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    letter-spacing: 0.5px;
    color: #3b3b3b;
    mix-blend-mode: normal;
}

/* add item end */

/* edit item start */

.edit-item-container {
    overflow-x: hidden;
}

.no-border-edit {
    border: none;
    border-color: white;
    box-shadow: 12px 15px 50px rgba(90, 108, 234, 0.07);
    border-radius: 10px;
}

.no-border-edit1 {
    border: none;
    border-color: white;
    box-shadow: 12px 15px 50px rgba(90, 108, 234, 0.07);
    border-radius: 10px;
    width: 30%;
}

.edititem-button {
    background: linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%);
    border-radius: 15px;
    /* padding-bottom: 10%; */
}

/* .input-91 {
    background-color: #fff !important;
    border: none !important;
} */

.phone-input-signup {
    /* display: flex;
    align-items: center; */
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: none;
    outline: none;
    white-space: nowrap;
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 0px 20px 0px #ececec;
    border-radius: 15px;
}

.input-login-phone {
    color: #000000;
    border: none;
    border-radius: 0px;
}

.input-login-phone:focus {
    box-shadow: none;
    border: none !important;
    background-color: none;
}

.input-login-phone:active {
    box-shadow: none;
    border: none !important;
    background-color: none;
}

.input-login-phone:disabled {
    box-shadow: none;
    border: none !important;
    background-color: none !important;
}

/* .item-edit-btn{
    padding-bottom: 5%;
} */

/* edit item stop */

.deal-today-input {
    line-height: 1.5;
    font-size: 13px;
    outline: none;
    padding: 0.85rem 1.5rem;
    white-space: nowrap;
    background: #fff;
    border: 1px solid #f4f4f4;
    box-shadow: 12px 15px 50px rgba(90, 108, 234, 0.07);
    border-radius: 15px;
}

/* bottomsheet */
[data-rsbs-overlay] {
    z-index: 9999 !important;
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
    z-index: 9999 !important;
}

.store-menu {
    background: #ffffff;
    /* border: 1px solid #fd8814; */
    /* box-shadow: 0px 4px 14px #5768ec1f; */
    border-radius: 19px;
}

.print-button {
    background-color: #8cb7ff;
    border-radius: 15px;
    border: none;
    width: 45%;
    white-space: nowrap;
    color: #ffffff;
    font-weight: 650;
    font-size: 1.1em;
}


.input-register-user {
    display: flex;
    align-items: center;
    padding: 1rem 0.75rem;
    line-height: 1.5;
    color: none;
    outline: none;
    text-align: center;
    white-space: nowrap;
    background-color: #ffeded;
    border: none;
    border-radius: 15px;
}

.input-register-details {
    color: #000000;
    border: none;
    outline: none;
    border-radius: 0px;
    background-color: #ffeded !important;
}

.input-register-details:focus {
    box-shadow: none;
    background-color: #ffeded !important;
}

.input-register-details:active {
    box-shadow: none;
    background-color: #ffeded !important;
}

.input-register-details:disabled {
    box-shadow: none;
    background-color: none !important;
}

input[id="end_date"] {
    position: relative;
}

input[id="start_date"] {
    position: relative;
}

input[type="date"][id="start_date"]::-webkit-calendar-picker-indicator {
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    user-select: none;
    bottom: 0;
    /* background-color: #000000; */
    left: 0;
    right: 0;
    top: 0;
    width: auto;

    height: auto;
}

input[type="date"][id="end_date"]::-webkit-calendar-picker-indicator {
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    user-select: none;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

    width: auto;
    height: auto;
}


.cart-customise {
    font-size: .9em;
    font-weight: 700;
    color: #4d4d4d;
}


.display-delete-visible {
    color: #fff;
    display: block;
    white-space: nowrap;
    visibility: visible;
    background-color: #FF3B3B !important;
    width: 25vw;
    transition: all .2s linear;
    animation-timing-function: linear;
}

.slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.slick-dots.slick-thumb li {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: #dac2ab;
}

.slick-dots.slick-thumb li.slick-active {
    background-color: #fea24b;
    width: 25px;
}


/* ========================================================= */
s,
strike {
    text-decoration: none;
    position: relative;
}

s::before,
strike::before {
    top: 50%;
    /*tweak this to adjust the vertical position if it's off a bit due to your font family */
    background: red;
    /*this is the color of the line*/
    opacity: .7;
    content: '';
    width: 110%;
    position: absolute;
    height: .2em;
    border-radius: .1em;
    left: -5%;
    white-space: nowrap;
    display: block;
    transform: rotate(-15deg);
}

s.straight::before,
strike.straight::before {
    transform: rotate(0deg);
    left: -1%;
    width: 102%;
}

.progress {
    height: .5rem !important;
}



/* --------------------------- toggle button Start ------------------------------- */

.switch {
    position: relative;
    display: inline-block;
    width: 18rem;
    height: 2.8rem;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    font-size: 13px;
    bottom: 0;
    background-color: #fea24b;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 100px;
}

.slider:before {
    position: absolute;
    content: "Email Login";
    height: 37px;
    width: 135px;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 0px;
    background-color: #fff;
    color: #fea24b;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
    border-radius: 100px;
}



input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(135px);
}

.slider:after {
    content: 'OTP Login';
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 210px;
    font-family: Verdana, sans-serif;
}

input:checked+.slider:after {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    left: 65px;
    content: 'Email Login';
}

input:checked+.slider:before {
    width: 9rem;
    font-weight: 700;
    content: 'OTP Login';
}
/* Add this to your CSS file for debugging */
.parent-element {
    outline: 1px solid red; /* Highlight the parent element */
  }

  .sticky-element {
    outline: 1px solid blue; /* Highlight the sticky element */
    position: sticky;
    top: 0;
    z-index: 50;
    background: white; /* Ensure it has a background */
  }
  .sticky-search {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0; /* Adjust this value as needed */
    z-index: 1000; /* Ensure it's above other content */
    background-color: white; /* Add background color to avoid overlap issues */
    padding-top: 10px;
    padding-bottom: 10px;
}
/* --------------------------- toggle button End ------------------------------- */
[data-rsbs-header="true"] {
    display: none; /* Hides the header */
  }
  